@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

/* Fix for Quill bullet points */
.ql-editor ul > li::before {
  content: '\2022'; /* Bullet character */
  list-style-type: none !important; /* Override potential conflicting styles */
}

/* Ensure padding for bullet points (Quill default) */
.ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
}

/* Ensure list-style-type is none for the LI itself */
 .ql-editor ul > li {
    list-style-type: none !important; /* Override potential conflicting styles */
 }
